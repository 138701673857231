@import '../../styles/breakpoints';

.container {
  list-style: none;
  display: flex;
  gap: var(--size-spacing-03);
  align-items: stretch;
}

.content {
  padding: var(--size-spacing-05) 0 var(--size-spacing-05) 0;
  width: 100%;
}

.container + .container .content {
  border-top: 1px solid var(--color-neutral-600);
}

.avatar {
  padding: var(--size-spacing-05) 0;
  flex: 0 0 36px;
  align-items: center;
}

.totals {
  padding: var(--size-spacing-05) 0;
  display: flex;
  align-items: center;
}

@include device-large {
  .container {
    gap: var(--size-spacing-04);
  }

  .avatar {
    flex: 0 0 46px;
  }
}

.header {
  display: flex;
  width: 100%;
}

.donorName {
  flex-grow: 1;
}

.date {
  align-self: center;
}

.donationMessage {
  word-wrap: break-word;
  overflow-wrap: anywhere;
  width: 100%;
}

.amount {
  font-size: var(--font-size-05);
}

.giftAidAmount {
  font-size: var(--font-size-01);
}

.cancelled {
  color: var(--color-grey-300);
}